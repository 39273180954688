import fetch from 'libs/fetchWithAuth';
import Cookies from 'cookies';
import getConfig from 'next/config';
import { NextApiRequest, NextApiResponse } from 'next';

const {
	publicRuntimeConfig: { FRONTEND_URL },
} = getConfig();

class Authentication {
	static async login(login, pass) {
		try {
			const json = await fetch(process.env.FRONTEND_URL + '/api/ext/auth', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ login, pass }),
			});

			if (!json?.resp) return null;

			return json;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	static async getTokenForPublicUrl(hash, req, res) {
		try {
			const json = await fetch(
				process.env.FRONTEND_URL + '/api/ext/auth-by-hash',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ hash }),
				}
			);

			if (!json?.resp) return null;

			const token = json.resp;
			const cookies = new Cookies(req, res);
			cookies.set('next-auth.session-token', token, {
				httpOnly: false,
			});

			try {
				cookies.set('__Secure-next-auth.session-token', token, {
					httpOnly: false,
					secure: true
				});
			} catch (error) {
				console.log('errnoo', error)
			}


			const newReq = {
				...req,
				headers: {
					...req.headers,
					Authorization: `Bearer ${token}`,
				},
			};

			return newReq;
		} catch (err) {
			console.log('errno', err);
			return null;
		}
	}

	static async getTokenForPublicUrlByHash(hash) {
		try {
			const json = await fetch(
				process.env.FRONTEND_URL + '/api/ext/auth-by-hash',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ hash }),
				}
			);

			console.log('jsonn', json, hash);

			if (!json?.resp) return null;
			return json;
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	static logout() {}

	static checkForExistingToken(req) {
		return fetch(process.env.FRONTEND_URL + '/api/ext/check-for-existing-token', {}, req);
	}

	static async getTokenForCode(body) {

		try {
			const json = await fetch(
				process.env.FRONTEND_URL + '/api/ext/auth-by-code',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ body }),
				}
			);
			console.log('json', json);

			if (!json?.resp) {
				return null;
			}

			if (!json?.resp?.token) {
				return null;
			}

			const token = json.resp.token;

			return json;
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	static setCookie(name, value, days) {
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + days);

		const cookieString = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;

		document.cookie = cookieString;
	}
}

export default Authentication;
